import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { useEffect } from "react";

import TrackableLink from "../../../atoms/trackable-link/TrackableLink";
import withFunnelWrapper from "../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import PageTitle from "../../../molecules/page-title/PageTitle";
import SectionTitle from "../../../molecules/section-title/SectionTitle";
import Meta from "../../../organisms/meta/meta";
import {
  useDataLayerProperties,
  useResult,
} from "../../../organisms/result/use-cases/result-use-cases";
import { CountryCode } from "../../../settings/countries";
import { PageId } from "../../../settings/pages";
import { Events } from "../../../utils/analytics";
import { getMobileOS, getWindow } from "../../../utils/browser-features";
import { formatDate } from "../../../utils/date";
import useTrackPageViewed from "../../../utils/hooks/useTrackPageViewed";
import * as styles from "../../Welcome.module.scss";

const appMockES: string =
  require("../../../images/pages/results/welcome/invite-friends-es.png").default;
const appMockDE: string =
  require("../../../images/pages/results/welcome/invite-friends-de.png").default;
const appMockFR: string =
  require("../../../images/pages/results/welcome/invite-friends-fr.png").default;
const appMockIT: string =
  require("../../../images/pages/results/welcome/pet-friends-it.png").default;
const appMockPT: string =
  require("../../../images/pages/results/welcome/pet-friends-pt.png").default;
const Dots: string = require("../../../images/pages/results/welcome/dots.svg").default;

const mockAppImages: { [key in keyof typeof CountryCode]: string } = {
  ES: appMockES,
  DE: appMockDE,
  FR: appMockFR,
  IT: appMockIT,
  PT: appMockPT,
};

const Page = () => {
  const result = useResult();
  const { t, i18n } = useTranslation();
  const dataLayerProperties = useDataLayerProperties();
  const dataLayer = getWindow()?.dataLayer || [];
  const iosSVG: string =
    require(`../../../images/molecules/app-download-buttons/app-store-${result?.country?.toLowerCase()}.svg`).default;

  const androidSVG: string =
    require(`../../../images/molecules/app-download-buttons/google-play-${result?.country?.toLowerCase()}.svg`).default;

  const qrCode: string =
    require(`../../../images/pages/results/welcome/download-app-qr-code-${result?.country?.toLowerCase()}.svg`).default;

  useTrackPageViewed(Events.WELCOME_VIEWED_BROWSER);

  useEffect(
    () =>
      dataLayerProperties &&
      dataLayer.push({ event: Events.PRODUCT_BOUGHT, ...dataLayerProperties })[dataLayerProperties]
  );

  return (
    <>
      {result && (
        <>
          <Meta
            title={t("common.page.title")}
            lang={result.country}
            bodyClass={`tp-funnel ${styles.welcome}`}
          />

          <section>
            <Dots />
            <PageTitle
              title={
                result.pet_parent_first_name
                  ? parse(
                      t("pg_welcome.title", {
                        petParentName: result.pet_parent_first_name,
                        petName: result.pet_name,
                      })
                    )
                  : t("welcome.title.title")
              }
              subtitle={t("pg_welcome.subtitle", {
                policyStartDate: formatDate(result.policy_start_date, i18n.language),
              })}
            >
              <div className={styles.appInfoWrapper}>
                <div className={styles.downloadApp}>
                  <section className={styles.appDetails}>
                    <SectionTitle
                      title={t("pg_welcome.app_download.title")}
                      adoptionClassName={styles.sectionTitle}
                    />
                    {getMobileOS() === "iOS" ? (
                      <TrackableLink
                        className={styles.button}
                        to={`https://apps.apple.com/${result?.country?.toLowerCase()}/app/id1514417760`}
                        extraEventProperties={{ name: "ios-link" }}
                      >
                        <img src={iosSVG} alt={t("app_banner.ios.alt")} />
                      </TrackableLink>
                    ) : (
                      <TrackableLink
                        className={styles.button}
                        to={`https://play.google.com/store/apps/details?id=com.barkibu.app&hl=${result?.country?.toLowerCase()}`}
                        extraEventProperties={{ name: "android-link" }}
                      >
                        <img src={androidSVG} alt={t("app_banner.android.alt")} />
                      </TrackableLink>
                    )}
                    <ul>
                      {[1, 2, 3, 4, 5, 6].map((i) => (
                        <li key={i}>{parse(t(`pg_welcome.app_download.list_items.${i}`))}</li>
                      ))}
                    </ul>
                  </section>
                  <div className={styles.qrCode}>
                    <figure>
                      <img src={qrCode} alt={""} className={styles.qrCode} />
                      <figcaption>{t("pg_welcome.app_download.qr_code.figcaption")}</figcaption>
                    </figure>
                  </div>
                </div>
                <div className={styles.petFriends}>
                  <img
                    src={mockAppImages[result.country as CountryCode]}
                    placeholder="blurred"
                    alt="app mockup"
                  />
                </div>
              </div>
            </PageTitle>
          </section>
        </>
      )}
    </>
  );
};

export default withFunnelWrapper(Page, "", PageId.welcome);

export const query = graphql`
  query WelcomeQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
